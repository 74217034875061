import React, { Component, useState } from "react"
import * as constants from "../../app.constant"
import * as Utils from "../../app.utils"
import deviceStore from "../../store/deviceStore"
import customerStore from "../../store/customerStore"
import mobileIcon from "../../assets/images/product/mobile.png"
import tabletIcon from "../../assets/images/product/tablet.png"
import wearableIcon from "../../assets/images/product/wearable.png"
import imeiExample from "../../assets/images/imei_example2_guide.png"
import Collapsible from "react-collapsible"
import watchface from "../../assets/images/product/watch_face.png"
import loaderStore from "../../store/loaderStore"
import { Trans, Translation } from "gatsby-plugin-react-i18next"
import { PrecontractualPopup } from "../product/PrecontractualPopup"
import { env } from "../../app.constant";

const ImeiInput = (props) => {
  const [touched, setTouched] = useState(false);
  const { onChange, className } = props;

  function changed(e) {
    setTouched(true)
    onChange(e)
  }


  return <input
    {...props}
    onChange={changed}
    className={touched ? className : "form-control"}
  />
}

class ProductComponent extends Component {
  state = {
    isMonthly: true,
    isYearly: false,
    // toolTipData: `<p>Any type of <strong>physical breakage</strong></p> <p>Any <strong>liquid breakage</strong> (Water, Juice, Beer!)</p><p>Max repair value <strong>₱${Utils.numberWithCommas(deviceStore.deviceRrp)}<strong></p>`,
    isMobile: true,
    isTablet: false,
    isWearable: false,
    imeiNumber: "",
    imeiValid: false,
    imeiError: null,
    serialNumber: "",
    serialValid: false,
    serialError: "",
    showPrecontract: false,
    inputDisable: false,
    deviceModel: "",
    deviceModelDesc : "",
    deviceActivationDate : "",
    voucherCode: constants.getVoucherCode,
    voucherError: null,
    voucherProductCodes: "",
    voucherDeviceModelNames: null,
    cid: constants.getCid,
    token: constants.getToken,
    oriTokenValue: "",
  }

  componentDidMount() {
    if (constants.getVoucherCode) {
      this.onVoucherChanged(constants.getVoucherCode)
    } else {
      this.onVoucherChanged("")
    }

    if(localStorage.getItem("validKeyName") && localStorage.getItem("errorKey") && localStorage.getItem("errorMsg")) {
      if(localStorage.getItem("errorKey") === "serialError") {
        this.selectDevice(constants.DEVICE_TYPE.TABLET)
        this.setState({
          serialNumber: deviceStore.deviceImei,
        })
      } else {
        this.selectDevice(constants.DEVICE_TYPE.MOBILE)
        this.setState({
          imeiNumber: deviceStore.deviceImei,
        })
      }
      setTimeout(()=>{
        this.setState({
          [localStorage.getItem("validKeyName")]: false,
          [localStorage.getItem("errorKey")]: localStorage.getItem("errorMsg"),
        })
        localStorage.removeItem("validKeyName")
        localStorage.removeItem("errorKey")
        localStorage.removeItem("errorMsg")
      }, 500)
    } else if(localStorage.getItem("isEditImei")) {
      if(deviceStore.deviceType === constants.DEVICE_TYPE.MOBILE) {
        this.selectDevice(constants.DEVICE_TYPE.MOBILE)
        this.setState({
          imeiNumber: deviceStore.deviceImei,
        })
      } else {
        this.selectDevice(constants.DEVICE_TYPE.TABLET)
        this.setState({
          serialNumber: deviceStore.deviceImei,
        })
      }
      localStorage.removeItem("isEditImei")
    } else {
      localStorage.clear()
      deviceStore.reset()
    }
  }

  getImeiOrSerialNoByToken = async (token) => {
    return await (
      await fetch(`${env.GET_IMEI_OR_SERIAL_NO_BY_TOKEN}?encryptedToken=${token}&countryCode=${env.GATSBY_COUNTRY_CODE}`, {
        method: "GET",
        headers: {
          "x-api-key": env.GET_PRODUCT_LIST_X_API_KEY,
          "Content-Type": "application/json",
        },
      })
    ).json()
  }

  selectProduct(productId) {
    switch (productId) {
      case constants.PRODUCT_IDS.PRODUCT_MONTHLY:
        this.setState({
          isMonthly: true,
          isYearly: false,
        })
        break
      case constants.PRODUCT_IDS.PRODUCT_YEARLY:
        this.setState({
          isMonthly: false,
          isYearly: true,
        })
        break
      default:
        this.props.navigate(constants.ROUTES.HOME)
        break
    }
  }

  selectDevice(deviceType) {
    this.resetState();
    switch (deviceType) {
      case constants.DEVICE_TYPE.MOBILE:
        this.setState({
          isMobile: true,
          isTablet: false,
          isWearable: false,
        })
        break
      case constants.DEVICE_TYPE.TABLET:
        this.setState({
          isMobile: false,
          isTablet: true,
          isWearable: false,
        })
        break
      case constants.DEVICE_TYPE.WEARABLE:
        this.setState({
          isMobile: false,
          isTablet: false,
          isWearable: true,
        })
        break
      default:
        this.props.navigate(constants.ROUTES.HOME)
        break
    }
  }

  resetState = () => {
    this.setState({
      imeiNumber: "",
      imeiValid: false,
      imeiError: null,
      serialNumber: "",
      serialValid: false,
      serialError: "",
      voucherCode: "",
      voucherError: null,
      voucherProductCodes: "",
      voucherDeviceModelNames: null,
    })
  }

  lengthValidation = async(value) => {
    loaderStore.showLoader(true);
    if (this.state.isMobile) {
      this.setState({ imeiValid: false, imeiError: null })
      const re = /^[0-9\b]+$/
      // if value is not blank, then test the regex
      if (value === "" || value === null || re.test(value)) {
        this.setState({ imeiNumber: value })
      }

      if (!value || value.split("").length <= 0){
        this.setState({
          imeiError: "deviceInfo.error.label3"
        })
      } else if (value && (value.split("").length > 15 || value.split("").length < 15)) {
        this.setState({
          imeiError: "deviceInfo.error.label1"
        })
        this.state.inputDisable = false;
      } else {
        this.setState({ imeiValid: true })
      }
    } else {
      this.setState({ serialValid: false, serialError: null })
      const re = /^[a-zA-Z0-9]+$/
      // if value is not blank, then test the regex
      if (value === "" || value === null || re.test(value)) {
        this.setState({ serialNumber: value })
      }

      if(!value || value.split("").length <= 0){
        this.setState({
          serialError: "deviceInfo.error.label5"
        })
      } else {
        if(this.state.isTablet) {
          if (value && (value.split("").length > 15 || value.split("").length < 11)) {
            this.setState({
              serialError: "deviceInfo.error.label8"
            })
          } else {
            this.setState({ serialValid: true })
          }
        } else if (this.state.isWearable) {
          if (value && (value.split("").length > 11 || value.split("").length < 11)) {
            this.setState({
              serialError: "deviceInfo.error.label4"
            })
          } else {
            this.setState({ serialValid: true })
          }
        }
      }
    }
    loaderStore.showLoader(false);
  }

  getPlanShouldUse = async (
    deviceUniqueId,
    forceToken = false) => {
    try {
      let validKeyName = (this.state.isMobile? "imeiValid" : "serialValid");
      const token = await Utils.getOrderToken(forceToken);
      const resp = await (
        await fetch(
          `${constants.env.GET_SAMSUNG_PRODUCT_LIST_API_URL}?device_unique_id=${deviceUniqueId
          }&device_id_type=${(this.state.isMobile?"imei":"serial-number")}`,
          {
            headers: {
              "bolt-country-code": constants.env.GATSBY_COUNTRY_CODE,
              "bolt-language-code": "EN",
              "bolt-tenant-id": constants.env.GATSBY_TENANT_ID,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
      ).json();
      if (resp?.message === "Forbidden") {
        throw new Error(resp.message)
      }

      let errorMsg = null;
      let errorKey = null;
      if(this.state.isMobile){
        errorKey = "imeiError";
      }else{
        errorKey = "serialError";
      }
      switch (resp._status.code) {
        // lets ensure SUCCESS code mean passing validation
        case constants.STATUS_CODE.SUCCESS:
          break;
        // else all status code that just show `...IMEI is not eligible for protection...` message
        case constants.STATUS_CODE.INVALID_REQUEST_PARAMETERS:
        case constants.STATUS_CODE.UNSUPPORTED_DEVICE_MODEL:
        case constants.STATUS_CODE.NOT_EXISTS_SAMSUNG_DEVICE_IMEI:
        default:
          errorMsg = "deviceInfo.error.label7"
          break
      }
      this.setState({
        [validKeyName]: !errorMsg,
        [errorKey]: errorMsg,
      });
        // optional filter
      let filteredProducts = resp.products?.filter(
        ({ insurance_company_type_code }) =>
          insurance_company_type_code === "Bolttech"
      )
      return {
        result: !errorMsg,
        plans: filteredProducts,
      }
    } catch (error) {
      if (error.message === "Forbidden") {
        // retries when token is not work for api call
        return this.getPlanShouldUse(
          deviceUniqueId,
          true
        );
      }
    }
    return false;
  }

  deviceVerificationApiCall = async (
    deviceUniqueId,
    forceToken = false
  ) => {
    this.state.inputDisable = true
    try {
      let validKeyName = (this.state.isMobile? "imeiValid" : "serialValid");
      const token = await Utils.getOrderToken(forceToken)
      if (token === null) throw new Error("No token found.")

      const resp = await (
        await fetch(constants.env.DEVICE_VERIFICATION_API_URL, {
          method: "POST",
          headers: {
            "bolt-country-code": constants.env.GATSBY_COUNTRY_CODE,
            "bolt-language-code": "EN",
            "bolt-tenant-id": constants.env.GATSBY_TENANT_ID,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            device_unique_id: deviceUniqueId,
            partner_code: constants.env.GATSBY_PARTNER_CODE,
            device_id_type: (this.state.isMobile?"imei":"serial-number"),
          }),
        })
      ).json()
      if (resp?.message === "Forbidden") {
        this.state.inputDisable = false
        throw new Error(resp.message)
      }

      // deviceStore.deviceSerialNumber = resp2.device_serial_number
      this.state.inputDisable = false
      let errorMsg = null
      let errorKey = null
      let voucherError = false
      if(this.state.isMobile){
        errorKey = "imeiError"
      }else{
        errorKey = "serialError"
      }
      switch (resp._status.code) {
        // lets ensure SUCCESS code mean passing validation
        case constants.STATUS_CODE.SUCCESS:
          // old code, which purpose is to update google analytic, ignore first
          //   updateGuestDetailsInDFP(null, null, deviceImei, dfpStore.guestId)
          break

        // if is validation return ALREADY_REGISTERED_DEVICE, give special message
        case constants.STATUS_CODE.ALREADY_REGISTERED_DEVICE:
          errorMsg = "deviceInfo.error.label2"
          break
        // if is validation return UNSUPPORTED_EULA_ACKNOWLEGDE_DATE, give special message
        case constants.STATUS_CODE.UNSUPPORTED_EULA_ACKNOWLEGDE_DATE:
          errorMsg = "deviceInfo.error.label6"
          break

        // else all status code that just show `...IMEI is not eligible for protection...` message
        case constants.STATUS_CODE.INVALID_REQUEST_PARAMETERS:
        case constants.STATUS_CODE.UNSUPPORTED_DEVICE_MODEL:
        case constants.STATUS_CODE.NOT_EXISTS_SAMSUNG_DEVICE_IMEI:
        default:
          errorMsg = "deviceInfo.error.label7"
          break
      }
      // ==> DSTR-4999 validation only for Q5B5 with voucher apply
      const q5b5Models = this.state.voucherDeviceModelNames
      if (resp._status.code === constants.STATUS_CODE.SUCCESS && this.state.voucherCode && !q5b5Models.some(model => resp.result.device_model_name.includes(model))) {
        this.setState({ voucherError: "deviceInfo.voucher.error.2" })
        voucherError = true
      }
      // <==
      this.setState({
        [validKeyName]: !errorMsg,
        [errorKey]: errorMsg,
      })
      const {
        result: { device_model_full_id: device_model, device_model_name, device_activation_date },
      } = resp
      return {
        result: !errorMsg && !voucherError,
        device_model,
        device_model_name,
        device_activation_date
      }
    } catch (error) {
      if (error.message === "Forbidden") {
        // retries when token is not work for api call
        return this.deviceVerificationApiCall(
          deviceUniqueId,
          true
        )
      }
      this.state.inputDisable = false
    }
    return false
  }

  async validateDeviceUniqueId() {
    loaderStore.showLoader(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          "x-api-key": constants.env.GET_PRODUCT_LIST_X_API_KEY,
          'Content-Type': 'application/json' }
      };
      const uniqueNumber = this.state.isMobile? this.state.imeiNumber : this.state.serialNumber
      fetch(`${constants.env.GET_POLICY_API_URL}?uniqueNumber=${uniqueNumber}`, requestOptions).then(res => res.json())
        .then(response => {
          if(response.obj.policyDocument){
            let policyDocument = response.obj.policyDocument
            if(policyDocument.policyStatus !== 'Active'){
              deviceStore.isRenewal = false;
              this.validateDevice();
            } else {
              this.setState({
                deviceModel: policyDocument.deviceModel,
                deviceModelDesc: policyDocument.deviceModel,
                deviceActivationDate: policyDocument.deviceActivationDate
              });
              deviceStore.isRenewal = true;
              deviceStore.productsModel = JSON.stringify(response.obj.productsModel[0])
              customerStore.customerId = policyDocument.customerId
              loaderStore.showLoader(false);
              this.goPlanSelectionPage();
            }
          } else {
            deviceStore.isRenewal = false;
            this.validateDevice();
          }
        })
    } catch (error) {
      console.log('validateDeviceUniqueId ERROR: ', error.message)
    }
  }

  onVoucherChanged = (value) => {
    this.setState({ voucherCode: value, voucherError: null, voucherProductCodes: "", voucherDeviceModelNames: null })
  }

  async validateVoucherCode() {
    loaderStore.showLoader(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          "x-api-key": constants.env.GET_PRODUCT_LIST_X_API_KEY,
          'Content-Type': 'application/json' }
      };
      fetch(`${constants.env.GET_POLICY_VOUCHER_API_URL}?voucherCode=${this.state.voucherCode}`, requestOptions).then(res => res.json())
        .then(response => {
          if (response.status === 'OK') {
            if (response.obj.productCode) {
              this.setState({ voucherProductCodes: response.obj.productCode.toString(), voucherDeviceModelNames: response.obj.deviceModelName })
              this.validateDevice();
            } else {
              this.setState({ voucherError: "deviceInfo.voucher.error.1" })
              loaderStore.showLoader(false);
            }

          } else {
            this.setState({ voucherError: "deviceInfo.voucher.error.1" })
            loaderStore.showLoader(false);
          }
        })
    } catch (error) {
      console.log('validateVoucherCode ERROR: ', error.message)
    }

  }

  async validateDevice() {
    loaderStore.showLoader(true);
    let curDate = new Date()
    const deviceUniqueId = this.state.isMobile? this.state.imeiNumber : this.state.serialNumber
    const {result: planResult, plans} = await this.getPlanShouldUse(deviceUniqueId);
    if(!planResult) {
      loaderStore.showLoader(false);
      return false;
    }
    
    const {
      result,
      device_model,
      device_model_name,
      device_activation_date
    } =  await this.deviceVerificationApiCall(deviceUniqueId);

    if(result) {
      this.setState({
        deviceModel: device_model,
        deviceModelDesc: device_model_name,
        deviceActivationDate: device_activation_date
      });
      this.goPlanSelectionPage();
    }
    loaderStore.showLoader(false);
    Utils.trackTiming(deviceUniqueId, curDate, "Device Verification");
  }

  async goPlanSelectionPage() {
    const startTime = new Date().getTime();
    const imei = this.state.isMobile ? this.state.imeiNumber : this.state.serialNumber
    const nonWearable = this.state.isTablet ? constants.DEVICE_TYPE.TABLET : constants.DEVICE_TYPE.MOBILE
    deviceStore.voucherProductCodes = this.state.voucherProductCodes
    deviceStore.voucherCode = this.state.voucherCode
    deviceStore.deviceImei = imei
    deviceStore.deviceType = this.state.isWearable ? constants.DEVICE_TYPE.WEARABLE : nonWearable
    deviceStore.deviceModel = this.state.deviceModel
    deviceStore.deviceModelDesc = this.state.deviceModelDesc
    deviceStore.deviceActivationDate = this.state.deviceActivationDate
    deviceStore.cid = (this.state.oriTokenValue === imei) ? this.state.cid : ""
    localStorage.setItem("startTime", startTime.toString())
    this.props.navigate(constants.ROUTES.PRODUCT)
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isMobile !== this.state.isMobile
    || prevState.isTablet !== this.state.isTablet
    || prevState.isWearable !== this.state.isWearable) {
      if(this.state.isMobile && this.state.imeiNumber) {
        await this.lengthValidation(this.state.imeiNumber);
      } else if ((this.state.isTablet || this.state.isWearable)
      && this.state.serialNumber) {
        await this.lengthValidation(this.state.serialNumber);
      }
    }
  }

  /* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
  render() {
    return (
      <div className="container product-page">
        <PrecontractualPopup
          show={this.state.showPrecontract}
          close={() => this.setState({ showPrecontract: false })}
          accept={() => this.goPlanSelectionPage()}
        />

        <div className="product-wrapper">
          <ul className="tab-navigation">
            <li
              className={
                this.state.isMobile ? "selectedProduct" : "unselectedPd"
              }
              onClick={() => this.selectDevice(constants.DEVICE_TYPE.MOBILE)}
            >
              {" "}
              <img src={mobileIcon} alt="mobile" width="20px" /> <br />
              <p className={this.props.isMobile?"display-12":"display-18"}>
                <Trans>deviceInfo.mobile</Trans>
              </p>
            </li>
            <li
              className={
                this.state.isTablet ? "selectedProduct" : "unselectedPd"
              }
              onClick={() => this.selectDevice(constants.DEVICE_TYPE.TABLET)}
            >
              {" "}
              <img
                src={tabletIcon}
                alt="mobile"
                width="30px"
                style={{ paddingTop: "5px", paddingBottom: "5px" }}
              />{" "}
              <br />
              <p className={this.props.isMobile?"display-12":"display-18"}>
                <Trans>deviceInfo.tablet</Trans>
              </p>
            </li>
            <li
              className={
                this.state.isWearable ? "selectedProduct" : "unselectedPd"
              }
              onClick={() => this.selectDevice(constants.DEVICE_TYPE.WEARABLE)}
            >
              {" "}
              <img src={wearableIcon} alt="mobile" width="25px" /> <br />
              <p className={this.props.isMobile?"display-12":"display-18"} style={{'lineHeight':'1'}}>
                <Trans>deviceInfo.wear</Trans>
              </p>
            </li>
          </ul>
        </div>

        <div>
          {this.state.isMobile && (
            <div className="form-group">
              <label className="form-field-label">
                <Trans>deviceInfo.imei.label</Trans>
              </label>
              <Translation>
                {(t, { i18n }) => (
                  <ImeiInput
                    name="imeiNumber"
                    value={this.state.imeiNumber}
                    onChange={e => this.lengthValidation(e.target.value)}
                    className={`form-control ${
                      this.state.imeiError || !this.state.imeiValid
                        ? "invalid-control invalid-field"
                        : "valid-field"
                    }`}
                    type="text"
                    maxLength="20"
                    placeholder={t("deviceInfo.imei.placeholder")}
                    readOnly = {this.state.inputDisable}
                  />
                )}
              </Translation>

              <div className="display-smal">
                <p>
                  <Trans>deviceInfo.imei.info</Trans>
                </p>
              </div>
              {this.state.imeiError && (
                <label className="error"><Trans>{this.state.imeiError}</Trans></label>
              )}
            </div>
          )}
          {(this.state.isWearable || this.state.isTablet) && (
            <div className="form-group">
              <label className="form-field-label">
                <Trans>deviceInfo.serial.label</Trans>
              </label>
              <Translation>
                {(t, { i18n }) => (
                  <ImeiInput
                    name="serialNumber"
                    value={this.state.serialNumber}
                    onChange={e => this.lengthValidation(e.target.value)}
                    className={`form-control ${
                      this.state.serialError || !this.state.serialValid
                        ? "invalid-control invalid-field"
                        : "valid-field"
                    }`}
                    type="text"
                    maxLength="20"
                    placeholder={t("deviceInfo.serial.placeholder")}
                  />
                )}
              </Translation>
              <div className="display-smal">
                <p>
                  <Trans>deviceInfo.serial.info</Trans>
                </p>
              </div>
              {this.state.serialError && (
                <label className="error">
                <Trans>
                  {this.state.serialError}
                </Trans></label>
              )}
            </div>
          )}
          {/* <p>{((this.state.imeiError || !this.state.imeiValid))}</p> */}
        </div>
        {constants.env.GATSBY_COUNTRY === 'NL' && (
          <div className="form-group display-14">
            <label className="form-field-label">
              <Trans>deviceInfo.voucher.label</Trans>
            </label>
            <Translation>
              {(t, { i18n }) => (
                <input
                  name="voucherCode"
                  value={this.state.voucherCode}
                  onChange={e => this.onVoucherChanged(e.target.value)}
                  className={`form-control ${
                    this.state.voucherError ? "invalid-control" : ""
                  }`}
                  type="text"
                  placeholder={t("deviceInfo.voucher.placeholder")}
                />
              )}
            </Translation>
            {this.state.voucherError && (
              <label className="error">
                <Trans>{this.state.voucherError}</Trans>
              </label>
            )}
          </div>
        )}

        {this.state.isMobile && (
          <button
            type="button"
            className={this.props.isMobile?"btn btn-block":"btn btn-center"}
            disabled={this.state.imeiError || !this.state.imeiValid || this.state.voucherError}
            id="getPlanProductPageBtn"
            onClick={() => this.state.voucherCode.trim()!==""?this.validateVoucherCode():this.validateDeviceUniqueId()}
          >
            <Trans>deviceInfo.button</Trans>
          </button>
        )}
        {(this.state.isWearable || this.state.isTablet) && (
          <button
            type="button"
            className={this.props.isMobile?"btn btn-block":"btn btn-center"}
            disabled={this.state.serialError || !this.state.serialValid || this.state.voucherError}
            id="getPlanProductPageBtn"
            onClick={() => this.state.voucherCode.trim()!==""?this.validateVoucherCode():this.validateDeviceUniqueId()}
          >
            <Trans>deviceInfo.button</Trans>
          </button>
        )}

        <div className="how-to-get-imei-instructions">
          <div style={{ paddingTop: "30px" }}>
            {this.state.isMobile && (
              <p
                style={{
                  fontSize: "21px",
                  fontFamily: '"SamsungOneUILatin 700", sans-serif',
                  lineHeight: "24px",
                }}
              >
                <Trans>deviceInfo.find.imei.title</Trans>
              </p>
            )}
            {this.state.isTablet && (
              <p
                style={{
                  fontSize: "21px",
                  fontFamily: '"SamsungOneUILatin 700", sans-serif',
                  lineHeight: "24px",
                }}
              >
                <Trans>deviceInfo.find.imeiOrSerialNumber.title</Trans>
              </p>
            )}
            {this.state.isWearable && (
              <p
                style={{
                  fontSize: "21px",
                  fontFamily: '"SamsungOneUILatin 700", sans-serif',
                  lineHeight: "24px",
                }}
              >
                <Trans>deviceInfo.find.serial.title</Trans>
              </p>
            )}
            {/* <ol>
                            <li>Open your phone’s Settings.</li>
                            <li>Tap "About Phone".</li>
                            <li><p>Scroll down and you'll find the number listed under "IMEI (slot 1)".</p><img src={imeiExample} alt="imei example" width="215" /></li>
                            <li>Tap and hold to copy the IMEI number. </li>
                            <li>Paste IMEI number into the field. </li>
                        </ol> */}
          </div>
        </div>

        {this.state.isMobile && (
          <div>
            <div
              className="collapsible-wrap"
              style={{
                paddingTop: "10px"
              }}
            >
              <Collapsible open>
                <div className="option-wrap">
                  <Trans components={{ s: <span /> }}>
                    deviceInfo.find.imei.option2.s1
                  </Trans>
                </div>
                <div className="option-wrap">
                  <Trans components={{ s: <span /> }}>
                    deviceInfo.find.imei.option2.s2
                  </Trans>
                </div>
                <div className="option-wrap">
                  <Trans
                    components={{
                      s: <span />,
                      1: (
                        <p
                          style={{ display: "inline-block", width: this.props.isMobile?"240px":"390px" }}
                        />
                      ),
                    }}
                  >
                    deviceInfo.find.imei.option2.s3
                  </Trans>
                  <div style={{ display: "flex", justifyContent: "left", marginLeft: "35px" }}>
                    <img src={imeiExample} alt="imei example" width="255" />
                  </div>
                </div>
                <div className="option-wrap">
                  <Trans components={{ s: <span />,
                    1: <p style={{ display: "inline-block", width: this.props.isMobile?"240px":"390px" }} />, }}>
                    deviceInfo.find.imei.option2.s4
                  </Trans>
                </div>
                <div className="option-wrap">
                  <Trans components={{ s: <span /> }}>
                    deviceInfo.find.imei.option2.s5
                  </Trans>
                </div>
              </Collapsible>
            </div>
          </div>
        )}

        {this.state.isTablet && (
          <div className="collapsible-wrap">
            <div className="option-wrap">
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: '"SamsungOneUILatin 700", sans-serif',
                  lineHeight: "10px",
                }}
              >
                <Trans>
                  deviceInfo.find.imeiOrSerialNumber.subTitle1
                </Trans>
              </p>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span /> }}>
                deviceInfo.find.imeiOrSerialNumber.s1Laptop
              </Trans>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span />, 1: <strong /> }}>
                deviceInfo.find.imeiOrSerialNumber.s2Laptop
              </Trans>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span />, 1: <strong /> }}>
                deviceInfo.find.imeiOrSerialNumber.s3Laptop
              </Trans>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span /> }}>
                deviceInfo.find.imeiOrSerialNumber.s4Laptop
              </Trans>
            </div>

            <div className="option-wrap">
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: '"SamsungOneUILatin 700", sans-serif',
                  lineHeight: "10px",
                  marginTop: "10px",
                }}
              >
                <Trans>
                  deviceInfo.find.imeiOrSerialNumber.subTitle2
                </Trans>
              </p>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span />, 1: <strong /> }}>
                deviceInfo.find.imeiOrSerialNumber.s1Tablet
              </Trans>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span />, 1: <strong /> }}>
                deviceInfo.find.imeiOrSerialNumber.s2Tablet
              </Trans>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span /> }}>
                deviceInfo.find.imeiOrSerialNumber.s3Tablet
              </Trans>
            </div>
          </div>
        )}

        {this.state.isWearable && (
          <div className="collapsible-wrap">
            <div className="option-wrap">
              <Trans
                components={{
                  s: <span />,
                  1: <p style={{ display: "inline-block", width: this.props.isMobile?"240px":"390px" }} />,
                }}
              >
                deviceInfo.find.serial.s1
              </Trans>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span /> ,
                1: <p style={{ display: "inline-block", width: this.props.isMobile?"240px":"390px"  }} />,}}>
                deviceInfo.find.serial.s2
              </Trans>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span /> }}>
                deviceInfo.find.serial.s3
              </Trans>
              <div style={{ paddingLeft: "30px" }}>
                <img src={watchface} alt="imei s/n example" width="110" />
              </div>
            </div>
            <div className="option-wrap">
              <Trans components={{ s: <span /> }}>
                deviceInfo.find.serial.s4
              </Trans>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ProductComponent
