import React, { useEffect, useState } from 'react'
import ProductComponent from '../components/device-info/product.component'
import Layout from '../components/shared/layout'
import Steps from '../components/shared/steps.component'
import { graphql } from 'gatsby'
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { isMobile as checkMobile, ROUTES } from '../app.constant'

export default function DeviceEligibilityPage() {
  const { t } = useTranslation()
  const { navigate } = useI18next()
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    document.title = '';
    setIsMobile(checkMobile())
  }, [])

    return (
      <Layout header={true} isBackBtn={true} prevUrl={ROUTES.HOME}>
        <aside className="asideTmp">
          <div className="grid-aside">
            <div></div>
            <div style={{ color: "#2189FF", fontWeight: "700" }}>
              {" "}
              <p>
                <Trans>desktop.sidebar.step1</Trans>
              </p>{" "}
            </div>
            <div></div>
            <div style={{ color: "#7F7F7F", fontWeight: "500" }}>
              {" "}
              <p>
                <Trans>desktop.sidebar.step2</Trans>
              </p>{" "}
            </div>
            <div></div>
            <div style={{ color: "#7F7F7F", fontWeight: "500" }}>
              {" "}
              <label htmlFor="step3">
                <Trans>desktop.sidebar.step3</Trans>
              </label>{" "}
            </div>
          </div>
        </aside>
        <Steps step="1" pageTitle={t("step.s1")}></Steps>
        <ProductComponent navigate={navigate} isMobile={isMobile} />
      </Layout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`